import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Global, css } from '@emotion/react';
import SEO from '../components/organisms/seo';
import { media } from '../utils/media-queries';
import Close from '../components/atoms/close';
import RichText from '../components/atoms/richtext';

const KontaktPage = ({ data }) => {
  const {
    _rawAddress,
    _rawDescription,
    _rawMessage,
    mail,
  } = data.contact.nodes[0];

  return (
    <>
      <Global
        styles={css`
          .main-wrapper {
            background: var(--b);
            color: var(--w);
          }
        `}
      />
      <SEO siteTitle="Projekte" />
      <StyledProject>
        <h1 className="hl">Kontakt</h1>
        <Close />
        <div className="desc hl outl-c">
          <RichText blocks={_rawDescription} />
        </div>
        <div className="mail hl">
          <a href={`mailto:${mail}`} className="hl-hover">
            {mail.split('@')[0]} {'@'}
            <br />
            {mail.split('@')[1]}
          </a>
          <RichText blocks={_rawAddress} />
        </div>
        <div className="message">
          <p className="name underl-w">Erik Jan Rippmann</p>
          <div className="hl-fix">
            <RichText blocks={_rawMessage} />
          </div>
        </div>
      </StyledProject>
    </>
  );
};

const StyledProject = styled.section`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  h1 {
    padding-top: var(--sp-1-3);
    padding-left: var(--sp-1-3);
    color: var(--w);
  }

  .desc {
    padding-left: var(--sp-1-3);
    padding-top: var(--sp-3);
    padding-bottom: var(--sp-3);
  }

  .address,
  .mail {
    padding-left: var(--sp-8);
  }

  .address {
    padding-bottom: var(--sp-8);
  }

  .message {
    order: 2;
    padding-left: var(--sp-1-3);
    padding-bottom: var(--sp-1-3);

    .name {
      display: inline-block;
      font-weight: bold;
      margin-bottom: var(--sp-1);
      margin-left: 3px;
    }
  }

  @media ${media.L} {
    display: block;
    overflow: hidden;
    height: 100vh;

    h1 {
      position: sticky;
      top: 0;
    }

    .desc {
      padding-top: 0;
      padding-bottom: 0;
    }

    .address,
    .mail {
      padding-left: 0;
    }

    .address {
      padding-bottom: 0;
    }

    .desc,
    .mail {
      position: absolute;
      top: 40vh;
    }

    .address {
      position: absolute;
      top: 55vh;
    }

    .mail,
    .address {
      left: 50vw;
    }

    .message {
      position: absolute;
      bottom: var(--sp-1-3);
      padding-bottom: 0;
    }
  }
`;

export const query = graphql`
  query Contact {
    contact: allSanityContact {
      nodes {
        _rawAddress
        _rawDescription
        _rawMessage
        mail
      }
    }
  }
`;

KontaktPage.propTypes = {
  data: PropTypes.object,
};

export default KontaktPage;
